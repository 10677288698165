<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <!-- 容器 -->
      <div class="weui-tab">
        <div class="weui-tab__bd">
          <div
            id="tab1"
            class="
              weui-tab__bd-item weui-tab__bd-item--active
              public-task-surveyHistorys
            "
          >
            <div class="public-task-surveyHistorys-list">
              <div class="public-areas public-area-tasks margin-bottom-10" v-for="(item, index) in list" :key="index">
                <div class="weui-cells">
                  <div class="weui-cell public-area-tasks-item">
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/11.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        No.{{item.questionnaireId}}
                        ：{{item.questionnaireTitle}}
                      </p>
                      <p class="remark">令牌：{{item.answerIdentifier}}</p>
                      <p class="remark">
                        状态：<span class="remark">{{statusMap[item.answerStatus]}}</span>
                      </p>
                      <p class="remark">
                        奖励：<span class="text-green"
                          >{{item.scoreBonus}}
                          <i class="icon icon-public-coins coin text-green"></i
                        ></span>
                      </p>
                      <p class="remark">完成时间：{{item.finishTime | timeFormat('YYYY-MM-DD HH:mm:ss')}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <div class="weui-cell public-area-tasks-item">
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/11.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        No.25512
                        ：WJ1-【严格质检】汽车购买调查（停留、时长控制20分钟以上）
                      </p>
                      <p class="remark">令牌：qk18p5hp&nbsp;&nbsp;</p>
                      <p class="remark">
                        状态：<span class="remark">未完成</span>
                      </p>
                      <p class="remark">
                        奖励：<span class="text-green"
                          >0
                          <i class="icon icon-public-coins coin text-green"></i
                        ></span>
                      </p>
                      <p class="remark">完成时间：--</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <div class="weui-cell public-area-tasks-item">
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/13.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">No.25623 ：WJ2-Y30762455</p>
                      <p class="remark">令牌：p6n710hd&nbsp;&nbsp;</p>
                      <p class="remark">
                        状态：<span class="remark">未完成</span>
                      </p>
                      <p class="remark">
                        奖励：<span class="text-green"
                          >0
                          <i class="icon icon-public-coins coin text-green"></i
                        ></span>
                      </p>
                      <p class="remark">完成时间：--</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <div class="weui-cell public-area-tasks-item">
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/13.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">No.25623 ：WJ2-Y30762455</p>
                      <p class="remark">令牌：xbnzm2il&nbsp;&nbsp;</p>
                      <p class="remark">
                        状态：<span class="remark">未完成</span>
                      </p>
                      <p class="remark">
                        奖励：<span class="text-green"
                          >0
                          <i class="icon icon-public-coins coin text-green"></i
                        ></span>
                      </p>
                      <p class="remark">完成时间：--</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <div class="weui-cell public-area-tasks-item">
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/13.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">No.25623 ：WJ2-Y30762455</p>
                      <p class="remark">令牌：7z2dm5s7&nbsp;&nbsp;</p>
                      <p class="remark">
                        状态：<span class="remark">未完成</span>
                      </p>
                      <p class="remark">
                        奖励：<span class="text-green"
                          >0
                          <i class="icon icon-public-coins coin text-green"></i
                        ></span>
                      </p>
                      <p class="remark">完成时间：--</p>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div :class="['weui-loadmore', 'public-loadmore', {'hidden' : !isShowBottomLoading}]">
              <i class="weui-loading"></i>
              <span class="weui-loadmore__tips">正在加载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'home'"></tabbar>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import { answerRecord } from '../api/index'
import tabbar from '../components/tabbar/index.vue'
export default {
  name: "surveyHistory",
  components: {
    tabbar
  },
  data () {
    return {
      statusMap: {
        'P': '进行中',
        'S': '已作答提交',
        'C': '完成',
        'D': '被甄别提前结束',
        'F': '限额满',
        'U': '未知',
      },
      pageNum: 1,
      pageSize: 20,
      pageCount: 1, //	总共页数
      list: [], // 明细列表
      isShowBottomLoading: false
    }
  },
  watch: {
    pageNum: function () {
      this.doGetanswerRecord()
    }
  },
  created () {
    this.doGetanswerRecord()
    this.listenScroll()
  }, 
  methods: {
    // 提交用户信息
    async doGetanswerRecord () {
      let params = {
        param: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      let res = {}
      try {
        this.isShowBottomLoading = true
        res = await answerRecord(params)
        this.isShowBottomLoading = false
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          const list = result.userScoreRecordList
          this.list.push(...list)
          this.pageCount = result.pageCount
          
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.isShowBottomLoading = false
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    // 页面已经滑动到底部
    pageToBottom () {
      if (this.pageNum < this.pageCount) {
        this.pageNum++
      }
    },
    listenScroll () {
      const _this = this
      window.onscroll = throttle(function (e) {
        console.log('e:', e)
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let screenH = document.documentElement?.clientHeight || window.innerHeight
        let bodyH = document.body.scrollHeight
        if ((scrollTop + screenH + 10 - bodyH) > 0 ) {
          console.log('页面已经滑动到底部')
          _this.pageToBottom()
        }
      }, 500)
    }
  }
};
</script>
<style lang="css" scoped>
.public-container{
  margin: unset;
}
</style>

